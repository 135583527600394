import React from "react";
import { ToastContainer } from "react-toastify";
import i18n from "../i18n";
const App = React.lazy(() => import("./App"));
const RTLApp = React.lazy(() => import("./RTLApp"));

function Main() {
  return i18n.dir() === "ltr" ? (
    <>
      <App /> <ToastContainer />
    </>
  ) : (
    <>
      <RTLApp /> <ToastContainer />
    </>
  );
}

export default Main;
